import React from "react";
import FAQSection from "../../../../Common/FAQSection/FAQSection";

export const webApplicationFAQList = [
    {
        title: "What types of web apps can you develop?",
        iconPath: [null],
        description: (
            <>
                Impressit offers custom web app development for a wide variety
                of purposes and industries. The types of web apps we can develop
                for you include:
                <br />
                - E-commerce web apps that enable businesses to sell products
                and services online, and usually include features such as
                shopping carts, payment gateways, and order management systems.
                <br />
                - Productivity web apps designed to help users manage tasks,
                organize information, and increase productivity. Such as project
                management tools, note-taking apps, and calendar apps.
                <br />
                - Content management web apps that enable users to create,
                manage, and publish content online; for instance, blogging
                platforms, website builders, and content management systems
                (CMS).
                <br />
                - Education web apps that help users learn new skills and
                knowledge online, including language learning apps, online
                courses, and educational games.
                <br />
                - Healthcare and wellness web apps created to help users track
                and manage their health and fitness goals — from nutrition apps
                to patient monitoring systems.
                <br />- Travel and tourism web apps that provide information and
                booking services for travel and tourism-related activities. For
                example, hotel booking sites, flight booking sites, and travel
                guides.
            </>
        ),
    },
    {
        title: "How much does it cost to develop a web app?",
        iconPath: [null],
        description: (
            <>
                The cost of web app development can vary widely depending on
                several factors, such as the complexity of the app, the
                technologies used, the development team&apos;s location and
                experience, and the time frame for development. Simple web apps
                with basic functionality and limited features can cost between
                $5,000 to $20,000. More complex web apps with advanced features,
                custom design, and integration with third-party services can
                cost between $20,000 to $100,000 or more. Overall, the cost of
                web application development will depend on the specific
                requirements and needs of the project, and it&apos;s recommended
                to consult with a development team to get a more accurate
                estimate based on the specific project scope.
            </>
        ),
    },
    {
        title: "How long does it take to build a web application?",
        iconPath: [null],
        description: (
            <>
                The time it takes to build a web application can vary widely
                depending on several factors, such as the complexity of the
                application, the size of the development team, the technologies
                used, and the development process followed. However, to provide
                a general estimate, a web application development can take
                anywhere from a few weeks to several months or even years.
                <br />
                Simple web applications with basic functionality and limited
                features can be built in a matter of weeks, while more complex
                web applications with advanced features and custom design can
                take several months or more to complete. To ensure a successful
                and timely web app development process, it&apos;s recommended to
                work with a skilled and experienced development team and to
                establish clear communication and project management processes.
            </>
        ),
    },
    {
        title: "What technologies are used to create a web-based application?",
        iconPath: [null],
        description: (
            <>
                Some of the most popular technologies used to create a web-based
                application are HTML, CSS, JavaScript, server-side programming
                languages like PHP, Python, and Ruby, as well as databases like
                MySQL and PostgreSQL. Frameworks and libraries like React,
                Angular, Vue, and Node.js are also commonly used in web
                application development.
            </>
        ),
    },
];

function WebFAQSection() {
    return <FAQSection title="FAQ" FAQList={webApplicationFAQList} />;
}

export default WebFAQSection;
